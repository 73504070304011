import { Languages } from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import React from 'react';
import { useLanguageContext } from '../contexts/language-context';
import { usePageContext } from '../contexts/page-context';
import { termsAndConditionsLink } from '../static-content';
import LocalizedLink from './localized-link';

export type CookiePolicyPageProps = {
  className?: string;
};

const RoCookiePolicy = ({ className }: CookiePolicyPageProps) => {
  const pageContext = usePageContext();
  const { activeLanguage } = useLanguageContext();

  return (
    <div className={classNames('prose', className)}>
      <h1>POLITICA DE COOKIEURI</h1>
      <p>
        Acesta face parte din{' '}
        <LocalizedLink
          className="!text-gray-900 !hover:text-gray-800 !underline"
          to={termsAndConditionsLink.to[activeLanguage]}
        >
          Termeni şi Condiţii
        </LocalizedLink>
        .
      </p>
      <p>
        Serviciul nostru foloseste cookies la adresa digitala,{' '}
        <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a> ("Serviciul")
        iar prin folosirea "Serviciului", utilizatorul isi exprima acordul de
        conformitate privind politica de utilizare cookies si a tehnologiilor
        aferente. Politica noastra privind utilizarea cookies are scopul de a
        aduce la cunostinta ce sunt cookies, cum Mesteri Locali foloseste
        cookies, cum unii terti si furnizori de servicii externi folosesc
        cookies pe platforma noastra, tot ceea ce un utilizator trebuie sa
        cunoasca si care sunt optiunile acestuia despre cookies.
      </p>
      <h2>Ce sunt cookies?</h2>
      <p>
        Cookie-ul este un fisier text de mici dimensiuni pe care un site il
        salveaza in calculatorul sau dispozitivul tau mobil atunci cand este
        accesat. Datorita cookie-urilor, site-ul retine, pe o perioada de timp,
        actiunile si preferintele tale cum are fi datele de autentificare,
        limba, dimensiunea caracterelor si alte preferinte de afisare. Astfel,
        acestea nu mai trebuiesc reintroduse ori de cate ori accesezi site-ul
        sau navighezi de pe o pagina pe alta.
      </p>
      <h2>Cum folosim noi cookies?</h2>
      <p>
        Cand folosesti si accesezi siteul-ul nostru, exista posibilitatea de a
        plasa un anumit numar de fisiere de tip cookies in browser-ul tau.
        Utilizam cookies pentru urmatoarele motive:
      </p>
      <ul>
        <li>Pentru a activa anumite functionalitati ale site-ului nostru</li>
        <li>
          Pentru a gestiona si memora preferintele tale in urma utilizarii
          platformei noastre
        </li>
        <li>
          Pentru a implementa masuri de securitate si a detecta orice activitate
          frauduloasa
        </li>
        <li>Pentru a analiza modul in care site-ul este folosit</li>
        <li>Pentru a alerta pe inginerii site-ului în cazul unei erori</li>
      </ul>
      <h2>Cookies necesare</h2>
      <p>
        Aceste cookie-uri sunt strict necesare pentru furnizarea serviciilor
        prin intermediul platformei noastre si pentru a putea folosi anumite
        functionalitati, ca de exemplu accesul la zone sigure cum ar fi contul
        de utilizator sau functionalitatea de chat.
      </p>
      <div className="w-full overflow-x-auto">
        <table>
          <thead>
            <tr>
              <td>
                <b>Denumire</b>
              </td>
              <td>
                <b>Furnizor</b>
              </td>
              <td>
                <b>Scop</b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>cookies_consent_given</td>
              <td>
                <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a>
              </td>
              <td>
                Salveaza consintamantul pentru cookie-uri preferintele
                vizitatorului pe domeniul curent.
              </td>
            </tr>
            <tr>
              <td>last_selected_language, nf_country</td>
              <td>
                <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a>
              </td>
              <td>Salveaza preferinta de limba.</td>
            </tr>
            <tr>
              <td>
                wasBottomDrawerHandleUsed, wasTooltipUsed,
                showOnlyAvailableEntities, billingDetails
              </td>
              <td>
                <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a>
              </td>
              <td>
                Salveaza actiuni in timpul rezervarii necesare pentru
                functionarea corecta a acestuia.
              </td>
            </tr>
            <tr>
              <td>
                __stripe_mid, __stripe_mid, site_sid, locale, scfc, stripe.csrf
              </td>
              <td>
                <a href="https://stripe.com/en-ro/privacy">Stripe</a>
              </td>
              <td>
                Necesare pentru functionarea corecta a platilor, care sunt
                procesate de catre Stripe.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>Cookies analitice si de preferinte</h2>
      <p>
        Aceste cookies colecteaza informatii care sunt folosite pentru a ne
        ajuta sa intelegem cum este folosita platforma noastra si cat de
        eficiente sunt campaniile noastre de marketing sau pentru a ne ajuta sa
        personalizam experienta ta pe site.
      </p>
      <div className="w-full overflow-x-auto">
        <table>
          <thead>
            <tr>
              <td>
                <b>Denumire</b>
              </td>
              <td>
                <b>Furnizor</b>
              </td>
              <td>
                <b>Scop</b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Generat de Google registreaza un ID unic vizitatorului pentru a
                genera statistici despre cum un vizitator foloseste site-ul.
              </td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Folosit de Google pentru a accelera rezolvarea solicitarilor.
              </td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Generat de Google registreaza un ID unic vizitatorului pentru a
                genera statistici despre cum un vizitator foloseste site-ul.
              </td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Registreaza un ID unic generat aleatoriu pentru a salva alte
                informatii de campanii despre utilizator.
              </td>
            </tr>
            <tr>
              <td>collect</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Folosit pentru a trimite date la Google Analytics despre
                vizitator si dispozitivul acestuia. Urmareste utilizatorul pe
                canale si dispozitive de marketing.
              </td>
            </tr>
            <tr>
              <td>_hjid</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Cookie folosit de Hotjar cand un client acceseaza o pagina care
                are acest script. Seteaza un ID unic vizitatorului si identifica
                sesiunea acestuia in browser pentru a atribui activitatea
                vizitatorului aceluiasi ID.
              </td>
            </tr>
            <tr>
              <td>_hjid</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Cookie folosit de Hotjar cand un client acceseaza o pagina care
                are acest script. Seteaza un ID unic vizitatorului si identifica
                sesiunea acestuia in browser pentru a atribui activitatea
                vizitatorului aceluiasi ID.
              </td>
            </tr>
            <tr>
              <td>ajs_anonymous_id</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Acest cookie este folosit de Hotjar pentru a genera un ID unic
                aleatoriu vizitatorilor anonimi.
              </td>
            </tr>
            <tr>
              <td>_hjIncludedInSample</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Acest cookie se foloseste pentru a determina daca un vizitator
                este inclus intr-un funnel.
              </td>
            </tr>
            <tr>
              <td>_hjIncludedInSample</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Acest cookie se foloseste pentru a determina cand sesiunea unui
                vizitator este inclusa inntr-un funnel.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>Care sunt optiunile tale in legatura cu cookies?</h2>
      <p>
        Daca doresti sa stergi cookies sau sa configurezi browser-ul sa nu
        accepte cookies, te rugam sa accesezi pagina de ajutor a browser-ului pe
        care il folosesti sau urmatoarele articole:
      </p>
      <ul>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=en-GB">
            Google Chrome
          </a>
        </li>
        <li>
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a href="https://support.apple.com/kb/ph21411?locale=en_GB">Safari</a>
        </li>
      </ul>
      <p>
        <b>Google Analytics Opt Out</b>: Poti opta pentru abilitatea de a
        preveni ca datele tale sa fie folosite de Google Analytics, adaugand
        extensia Google Analytics Opt Out in browser-ul tau{' '}
        <a href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </a>
      </p>
      <p>
        <b>Hotjar</b>: Poti renunta la crearea unui profil de utilizator,
        stocarea de catre Hotjar a datelor despre utilizarea site-ului si
        utilizarea de catre Hotjar a cookie-urilor de urmarire pe alte site-uri,
        pentru opt-out urmareste link-ul{' '}
        <a href="https://www.hotjar.com/legal/compliance/opt-out">
          https://www.hotjar.com/legal/compliance/opt-out
        </a>
      </p>
      <p>
        <b>Do Not Track (DNT)</b>: Serviciul DNT, in limba romana ”Nu urmari”,
        poate fi configurat in browser-ul tau pentru a trimite un semnal special
        site-urilor web, companiilor de analiza, retelelor de publicitare,
        furnizorilor conectati si altor servicii web pe care le intlanesti in
        timpul navigarii pentru a opri urmarirea activitatii, accesand link-ul{' '}
        <a href="https://allaboutdnt.com/">https://allaboutdnt.com/</a>
      </p>
    </div>
  );
};

const HuCookiePolicy = ({ className }: CookiePolicyPageProps) => {
  const pageContext = usePageContext();
  const { activeLanguage } = useLanguageContext();

  return (
    <div className={classNames('prose', className)}>
      <h3>*Jelenleg csak román nyelven érhető el a Cookie Tájékoztató*</h3>
      <h1>POLITICA DE COOKIEURI</h1>
      <p>
        Acesta face parte din{' '}
        <LocalizedLink
          className="!text-gray-900 !hover:text-gray-800 !underline"
          to={termsAndConditionsLink.to[activeLanguage]}
        >
          Termeni şi Condiţii
        </LocalizedLink>
        .
      </p>
      <p>
        Serviciul nostru foloseste cookies la adresa digitala,{' '}
        <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a> ("Serviciul")
        iar prin folosirea "Serviciului", utilizatorul isi exprima acordul de
        conformitate privind politica de utilizare cookies si a tehnologiilor
        aferente. Politica noastra privind utilizarea cookies are scopul de a
        aduce la cunostinta ce sunt cookies, cum Mesteri Locali foloseste
        cookies, cum unii terti si furnizori de servicii externi folosesc
        cookies pe platforma noastra, tot ceea ce un utilizator trebuie sa
        cunoasca si care sunt optiunile acestuia despre cookies.
      </p>
      <h2>Ce sunt cookies?</h2>
      <p>
        Cookie-ul este un fisier text de mici dimensiuni pe care un site il
        salveaza in calculatorul sau dispozitivul tau mobil atunci cand este
        accesat. Datorita cookie-urilor, site-ul retine, pe o perioada de timp,
        actiunile si preferintele tale cum are fi datele de autentificare,
        limba, dimensiunea caracterelor si alte preferinte de afisare. Astfel,
        acestea nu mai trebuiesc reintroduse ori de cate ori accesezi site-ul
        sau navighezi de pe o pagina pe alta.
      </p>
      <h2>Cum folosim noi cookies?</h2>
      <p>
        Cand folosesti si accesezi siteul-ul nostru, exista posibilitatea de a
        plasa un anumit numar de fisiere de tip cookies in browser-ul tau.
        Utilizam cookies pentru urmatoarele motive:
      </p>
      <ul>
        <li>Pentru a activa anumite functionalitati ale site-ului nostru</li>
        <li>
          Pentru a gestiona si memora preferintele tale in urma utilizarii
          platformei noastre
        </li>
        <li>
          Pentru a implementa masuri de securitate si a detecta orice activitate
          frauduloasa
        </li>
        <li>Pentru a analiza modul in care site-ul este folosit</li>
        <li>Pentru a alerta pe inginerii site-ului în cazul unei erori</li>
      </ul>
      <h2>Cookies necesare</h2>
      <p>
        Aceste cookie-uri sunt strict necesare pentru furnizarea serviciilor
        prin intermediul platformei noastre si pentru a putea folosi anumite
        functionalitati, ca de exemplu accesul la zone sigure cum ar fi contul
        de utilizator sau functionalitatea de chat.
      </p>
      <div className="w-full overflow-x-auto">
        <table>
          <thead>
            <tr>
              <td>
                <b>Denumire</b>
              </td>
              <td>
                <b>Furnizor</b>
              </td>
              <td>
                <b>Scop</b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>cookies_consent_given</td>
              <td>
                <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a>
              </td>
              <td>
                Salveaza consintamantul pentru cookie-uri preferintele
                vizitatorului pe domeniul curent.
              </td>
            </tr>
            <tr>
              <td>last_selected_language, nf_country</td>
              <td>
                <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a>
              </td>
              <td>Salveaza preferinta de limba.</td>
            </tr>
            <tr>
              <td>
                wasBottomDrawerHandleUsed, wasTooltipUsed,
                showOnlyAvailableEntities, billingDetails
              </td>
              <td>
                <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a>
              </td>
              <td>
                Salveaza actiuni in timpul rezervarii necesare pentru
                functionarea corecta a acestuia.
              </td>
            </tr>
            <tr>
              <td>
                __stripe_mid, __stripe_mid, site_sid, locale, scfc, stripe.csrf
              </td>
              <td>
                <a href="https://stripe.com/en-ro/privacy">Stripe</a>
              </td>
              <td>
                Necesare pentru functionarea corecta a platilor, care sunt
                procesate de catre Stripe.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>Cookies analitice si de preferinte</h2>
      <p>
        Aceste cookies colecteaza informatii care sunt folosite pentru a ne
        ajuta sa intelegem cum este folosita platforma noastra si cat de
        eficiente sunt campaniile noastre de marketing sau pentru a ne ajuta sa
        personalizam experienta ta pe site.
      </p>
      <div className="w-full overflow-x-auto">
        <table>
          <thead>
            <tr>
              <td>
                <b>Denumire</b>
              </td>
              <td>
                <b>Furnizor</b>
              </td>
              <td>
                <b>Scop</b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Generat de Google registreaza un ID unic vizitatorului pentru a
                genera statistici despre cum un vizitator foloseste site-ul.
              </td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Folosit de Google pentru a accelera rezolvarea solicitarilor.
              </td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Generat de Google registreaza un ID unic vizitatorului pentru a
                genera statistici despre cum un vizitator foloseste site-ul.
              </td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Registreaza un ID unic generat aleatoriu pentru a salva alte
                informatii de campanii despre utilizator.
              </td>
            </tr>
            <tr>
              <td>collect</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Folosit pentru a trimite date la Google Analytics despre
                vizitator si dispozitivul acestuia. Urmareste utilizatorul pe
                canale si dispozitive de marketing.
              </td>
            </tr>
            <tr>
              <td>_hjid</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Cookie folosit de Hotjar cand un client acceseaza o pagina care
                are acest script. Seteaza un ID unic vizitatorului si identifica
                sesiunea acestuia in browser pentru a atribui activitatea
                vizitatorului aceluiasi ID.
              </td>
            </tr>
            <tr>
              <td>_hjid</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Cookie folosit de Hotjar cand un client acceseaza o pagina care
                are acest script. Seteaza un ID unic vizitatorului si identifica
                sesiunea acestuia in browser pentru a atribui activitatea
                vizitatorului aceluiasi ID.
              </td>
            </tr>
            <tr>
              <td>ajs_anonymous_id</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Acest cookie este folosit de Hotjar pentru a genera un ID unic
                aleatoriu vizitatorilor anonimi.
              </td>
            </tr>
            <tr>
              <td>_hjIncludedInSample</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Acest cookie se foloseste pentru a determina daca un vizitator
                este inclus intr-un funnel.
              </td>
            </tr>
            <tr>
              <td>_hjIncludedInSample</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Acest cookie se foloseste pentru a determina cand sesiunea unui
                vizitator este inclusa inntr-un funnel.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>Care sunt optiunile tale in legatura cu cookies?</h2>
      <p>
        Daca doresti sa stergi cookies sau sa configurezi browser-ul sa nu
        accepte cookies, te rugam sa accesezi pagina de ajutor a browser-ului pe
        care il folosesti sau urmatoarele articole:
      </p>
      <ul>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=en-GB">
            Google Chrome
          </a>
        </li>
        <li>
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a href="https://support.apple.com/kb/ph21411?locale=en_GB">Safari</a>
        </li>
      </ul>
      <p>
        <b>Google Analytics Opt Out</b>: Poti opta pentru abilitatea de a
        preveni ca datele tale sa fie folosite de Google Analytics, adaugand
        extensia Google Analytics Opt Out in browser-ul tau{' '}
        <a href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </a>
      </p>
      <p>
        <b>Hotjar</b>: Poti renunta la crearea unui profil de utilizator,
        stocarea de catre Hotjar a datelor despre utilizarea site-ului si
        utilizarea de catre Hotjar a cookie-urilor de urmarire pe alte site-uri,
        pentru opt-out urmareste link-ul{' '}
        <a href="https://www.hotjar.com/legal/compliance/opt-out">
          https://www.hotjar.com/legal/compliance/opt-out
        </a>
      </p>
      <p>
        <b>Do Not Track (DNT)</b>: Serviciul DNT, in limba romana ”Nu urmari”,
        poate fi configurat in browser-ul tau pentru a trimite un semnal special
        site-urilor web, companiilor de analiza, retelelor de publicitare,
        furnizorilor conectati si altor servicii web pe care le intlanesti in
        timpul navigarii pentru a opri urmarirea activitatii, accesand link-ul{' '}
        <a href="https://allaboutdnt.com/">https://allaboutdnt.com/</a>
      </p>
    </div>
  );
};

const EnCookiePolicy = ({ className }: CookiePolicyPageProps) => {
  const pageContext = usePageContext();
  const { activeLanguage } = useLanguageContext();

  return (
    <div className={classNames('prose', className)}>
      <h3>
        *As of now the Cookie Policy is available only in romanian language*
      </h3>
      <h1>POLITICA DE COOKIEURI</h1>
      <p>
        Acesta face parte din{' '}
        <LocalizedLink
          className="!text-gray-900 !hover:text-gray-800 !underline"
          to={termsAndConditionsLink.to[activeLanguage]}
        >
          Termeni şi Condiţii
        </LocalizedLink>
        .
      </p>
      <p>
        Serviciul nostru foloseste cookies la adresa digitala,{' '}
        <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a> ("Serviciul")
        iar prin folosirea "Serviciului", utilizatorul isi exprima acordul de
        conformitate privind politica de utilizare cookies si a tehnologiilor
        aferente. Politica noastra privind utilizarea cookies are scopul de a
        aduce la cunostinta ce sunt cookies, cum Mesteri Locali foloseste
        cookies, cum unii terti si furnizori de servicii externi folosesc
        cookies pe platforma noastra, tot ceea ce un utilizator trebuie sa
        cunoasca si care sunt optiunile acestuia despre cookies.
      </p>
      <h2>Ce sunt cookies?</h2>
      <p>
        Cookie-ul este un fisier text de mici dimensiuni pe care un site il
        salveaza in calculatorul sau dispozitivul tau mobil atunci cand este
        accesat. Datorita cookie-urilor, site-ul retine, pe o perioada de timp,
        actiunile si preferintele tale cum are fi datele de autentificare,
        limba, dimensiunea caracterelor si alte preferinte de afisare. Astfel,
        acestea nu mai trebuiesc reintroduse ori de cate ori accesezi site-ul
        sau navighezi de pe o pagina pe alta.
      </p>
      <h2>Cum folosim noi cookies?</h2>
      <p>
        Cand folosesti si accesezi siteul-ul nostru, exista posibilitatea de a
        plasa un anumit numar de fisiere de tip cookies in browser-ul tau.
        Utilizam cookies pentru urmatoarele motive:
      </p>
      <ul>
        <li>Pentru a activa anumite functionalitati ale site-ului nostru</li>
        <li>
          Pentru a gestiona si memora preferintele tale in urma utilizarii
          platformei noastre
        </li>
        <li>
          Pentru a implementa masuri de securitate si a detecta orice activitate
          frauduloasa
        </li>
        <li>Pentru a analiza modul in care site-ul este folosit</li>
        <li>Pentru a alerta pe inginerii site-ului în cazul unei erori</li>
      </ul>
      <h2>Cookies necesare</h2>
      <p>
        Aceste cookie-uri sunt strict necesare pentru furnizarea serviciilor
        prin intermediul platformei noastre si pentru a putea folosi anumite
        functionalitati, ca de exemplu accesul la zone sigure cum ar fi contul
        de utilizator sau functionalitatea de chat.
      </p>
      <div className="w-full overflow-x-auto">
        <table>
          <thead>
            <tr>
              <td>
                <b>Denumire</b>
              </td>
              <td>
                <b>Furnizor</b>
              </td>
              <td>
                <b>Scop</b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>cookies_consent_given</td>
              <td>
                <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a>
              </td>
              <td>
                Salveaza consintamantul pentru cookie-uri preferintele
                vizitatorului pe domeniul curent.
              </td>
            </tr>
            <tr>
              <td>last_selected_language, nf_country</td>
              <td>
                <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a>
              </td>
              <td>Salveaza preferinta de limba.</td>
            </tr>
            <tr>
              <td>
                wasBottomDrawerHandleUsed, wasTooltipUsed,
                showOnlyAvailableEntities, billingDetails
              </td>
              <td>
                <a href={pageContext.siteUrl}>{pageContext.siteUrl}</a>
              </td>
              <td>
                Salveaza actiuni in timpul rezervarii necesare pentru
                functionarea corecta a acestuia.
              </td>
            </tr>
            <tr>
              <td>
                __stripe_mid, __stripe_mid, site_sid, locale, scfc, stripe.csrf
              </td>
              <td>
                <a href="https://stripe.com/en-ro/privacy">Stripe</a>
              </td>
              <td>
                Necesare pentru functionarea corecta a platilor, care sunt
                procesate de catre Stripe.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>Cookies analitice si de preferinte</h2>
      <p>
        Aceste cookies colecteaza informatii care sunt folosite pentru a ne
        ajuta sa intelegem cum este folosita platforma noastra si cat de
        eficiente sunt campaniile noastre de marketing sau pentru a ne ajuta sa
        personalizam experienta ta pe site.
      </p>
      <div className="w-full overflow-x-auto">
        <table>
          <thead>
            <tr>
              <td>
                <b>Denumire</b>
              </td>
              <td>
                <b>Furnizor</b>
              </td>
              <td>
                <b>Scop</b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Generat de Google registreaza un ID unic vizitatorului pentru a
                genera statistici despre cum un vizitator foloseste site-ul.
              </td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Folosit de Google pentru a accelera rezolvarea solicitarilor.
              </td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Generat de Google registreaza un ID unic vizitatorului pentru a
                genera statistici despre cum un vizitator foloseste site-ul.
              </td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Registreaza un ID unic generat aleatoriu pentru a salva alte
                informatii de campanii despre utilizator.
              </td>
            </tr>
            <tr>
              <td>collect</td>
              <td>
                <a href="https://policies.google.com/privacy">Google</a>
              </td>
              <td>
                Folosit pentru a trimite date la Google Analytics despre
                vizitator si dispozitivul acestuia. Urmareste utilizatorul pe
                canale si dispozitive de marketing.
              </td>
            </tr>
            <tr>
              <td>_hjid</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Cookie folosit de Hotjar cand un client acceseaza o pagina care
                are acest script. Seteaza un ID unic vizitatorului si identifica
                sesiunea acestuia in browser pentru a atribui activitatea
                vizitatorului aceluiasi ID.
              </td>
            </tr>
            <tr>
              <td>_hjid</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Cookie folosit de Hotjar cand un client acceseaza o pagina care
                are acest script. Seteaza un ID unic vizitatorului si identifica
                sesiunea acestuia in browser pentru a atribui activitatea
                vizitatorului aceluiasi ID.
              </td>
            </tr>
            <tr>
              <td>ajs_anonymous_id</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Acest cookie este folosit de Hotjar pentru a genera un ID unic
                aleatoriu vizitatorilor anonimi.
              </td>
            </tr>
            <tr>
              <td>_hjIncludedInSample</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Acest cookie se foloseste pentru a determina daca un vizitator
                este inclus intr-un funnel.
              </td>
            </tr>
            <tr>
              <td>_hjIncludedInSample</td>
              <td>
                <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">
                  Hotjar
                </a>
              </td>
              <td>
                Acest cookie se foloseste pentru a determina cand sesiunea unui
                vizitator este inclusa inntr-un funnel.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>Care sunt optiunile tale in legatura cu cookies?</h2>
      <p>
        Daca doresti sa stergi cookies sau sa configurezi browser-ul sa nu
        accepte cookies, te rugam sa accesezi pagina de ajutor a browser-ului pe
        care il folosesti sau urmatoarele articole:
      </p>
      <ul>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=en-GB">
            Google Chrome
          </a>
        </li>
        <li>
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a href="https://support.apple.com/kb/ph21411?locale=en_GB">Safari</a>
        </li>
      </ul>
      <p>
        <b>Google Analytics Opt Out</b>: Poti opta pentru abilitatea de a
        preveni ca datele tale sa fie folosite de Google Analytics, adaugand
        extensia Google Analytics Opt Out in browser-ul tau{' '}
        <a href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </a>
      </p>
      <p>
        <b>Hotjar</b>: Poti renunta la crearea unui profil de utilizator,
        stocarea de catre Hotjar a datelor despre utilizarea site-ului si
        utilizarea de catre Hotjar a cookie-urilor de urmarire pe alte site-uri,
        pentru opt-out urmareste link-ul{' '}
        <a href="https://www.hotjar.com/legal/compliance/opt-out">
          https://www.hotjar.com/legal/compliance/opt-out
        </a>
      </p>
      <p>
        <b>Do Not Track (DNT)</b>: Serviciul DNT, in limba romana ”Nu urmari”,
        poate fi configurat in browser-ul tau pentru a trimite un semnal special
        site-urilor web, companiilor de analiza, retelelor de publicitare,
        furnizorilor conectati si altor servicii web pe care le intlanesti in
        timpul navigarii pentru a opri urmarirea activitatii, accesand link-ul{' '}
        <a href="https://allaboutdnt.com/">https://allaboutdnt.com/</a>
      </p>
    </div>
  );
};

const CookiePolicyPage = () => {
  const { activeLanguage } = useLanguageContext();

  const cookiePolicyClassNames = 'custom-container mx-auto pt-14 pb-16';

  if (activeLanguage === Languages.RO) {
    return <RoCookiePolicy className={cookiePolicyClassNames} />;
  }

  if (activeLanguage === Languages.HU) {
    return <HuCookiePolicy className={cookiePolicyClassNames} />;
  }

  return <EnCookiePolicy className={cookiePolicyClassNames} />;
};

export default CookiePolicyPage;
